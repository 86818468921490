import Helpers from './helpers'

export default class RenderPriceService {

  constructor(summary_selector, recap_selector, total_selector, variant = ''){
    this.summary_selector = summary_selector
    this.recap_selector = recap_selector
    this.total_selector = total_selector
    this.variant = variant
    this.helpers = new Helpers()
  }

  process(response){
    const summaryHTML = this.process_summary(response)
    const linesHTML = this.helpers.lines_mapper(response.lines)

    const totalLineHTML = this.helpers.total_line_mapper(response.totalPrice)
    const recapHTML = linesHTML + '<hr class="m-0">' + totalLineHTML

    let totalHTML = totalLineHTML
    if(this.variant != 'with_coupon_form'){
      totalHTML = '<hr class="m-0">' + totalHTML
    }


    this.render_action({summaryHTML, recapHTML, totalHTML})
  }

  render_action({summaryHTML, recapHTML, totalHTML}){
    this.summary_selector.innerHTML = summaryHTML
    this.recap_selector.innerHTML = recapHTML
    this.total_selector.innerHTML = totalHTML
  }

  process_summary(response) {
    const totalValuesObject = [{value: response.subTotalPrice, display: "Location"}]
    const linesCriteria = ["excess_redemption", "reduction", "coupon"]
    const linesToShow = response.lines.filter((line) => line.type && linesCriteria.includes(line.type))
    const linesSummary = totalValuesObject.concat(linesToShow)

    return this.helpers.lines_mapper(linesSummary)
  }
}
