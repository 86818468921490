import ReadSmore from 'read-smore'

function readMore (arrow_path, alt) {
  const readMores = document.querySelectorAll('.js-read-smore')

  const options = {
    isInline: false,
    moreText: `Voir plus`,
    lessText: `Voir moins`,
    wordsCount: 60
  }

  const RMs = ReadSmore(readMores, options)
  RMs.init()
}

window.readMore = readMore